import Phaser from 'phaser';
import {
  CST
} from '../CST';
import makeAnimations from '../Helpers/animations';
import attackData from '../Helpers/attackData';

export class PreloadScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.LOAD
    });
  }

  init(passedData) {}

  preload() {

    this.HEIGHT = 660;
    this.WIDTH = 660;
    this.CENTER_POINT_X = this.WIDTH / 2;
    this.CENTER_POINT_Y = this.HEIGHT / 2;
    this.PRELOAD_TIMER = 100;

    //Add black BG
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, this.WIDTH, this.HEIGHT);
    //Add stars
    this.particles = this.add.particles('particle');
    this.emitter = this.particles.createEmitter({
      x: 330,
      y: 330,
      bounce: true,
      lifespan: 1000,
      alpha: 0.6,
      speed: {
        min: 300,
        max: 360
      },
      gravityY: 0,
      scale: {
        start: 1,
        end: .7
      },
      quantity: 1,
      blendMode: 'ADD',
      timeScale: 0.7
    });

    let quad = {
      topLeftX: 126,
      topLeftY: 258,
      topRightX: 660 - 126,
      topRightY: 258,
      bottomLeftX: 126,
      bottomLeftY: 660 - 258,
      bottomRightX: 660 - 126,
      bottomRightY: 660 - 258
    };

    let preloader_graphic = this.add.image(330, 330, 'preloader');

    let preloader_graphic_height = preloader_graphic.height;

    this.add.text(330 - 40, 660 - 190, 'PRESENTS...', {
      fontFamily: 'VT323',
      color: '#41b1e4'
    });

    /* Scene screens */
    this.load.spritesheet('throwback', './game/generic/throwback_title.png', {
      frameWidth: 660,
      frameHeight: 660
    });
    this.load.spritesheet('press_btn', './game/generic/press.png', {
      frameWidth: 660,
      frameHeight: 48
    });

    this.load.spritesheet('screen_bg', './game/fighterbun/images/screens/screen_bg.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.image('screen_instruction1', './game/fighterbun/images/screens/screen_instruction1.png');
    this.load.image('screen_instruction2', './game/fighterbun/images/screens/screen_instruction2.png');
    this.load.image('screen_title', './game/fighterbun/images/screens/screen_title.png');
    this.load.spritesheet('screen_title_spritesheet', './game/fighterbun/images/screens/screen_title_spritesheet.png', {
      frameWidth: 660,
      frameHeight: 660
    });


    // reference
    this.load.image('screen_screenadjust', './game/fighterbun/images/screens/screen_screenadjust.png');


    /* Spritesheet characters */
    this.load.spritesheet('fingerbun_v2', './game/fighterbun/images/character/fingerbun_v2.png', {
      frameWidth: 55,
      frameHeight: 55
    });
    this.load.spritesheet('enemybun_v2', './game/fighterbun/images/character/enemybun_v2.png', {
      frameWidth: 55,
      frameHeight: 55
    });

    /* Effects */
    this.load.spritesheet('impact', './game/fighterbun/images/fx/impact.png', {
      frameWidth: 40,
      frameHeight: 40
    });
    this.load.image('impact_particle', './game/fighterbun/images/fx/impact_particle.png');

    this.load.spritesheet('spark', './game/fighterbun/images/fx/spark.png', {
      frameWidth: 55,
      frameHeight: 55
    });
    this.load.spritesheet('beam', './game/fighterbun/images/fx/beam.png', {
      frameWidth: 200,
      frameHeight: 25
    });

    this.load.image('shadow', './game/fighterbun/images/fx/shadow.png');

    /* Background */
    this.load.image('bg1', './game/fighterbun/images/bg/bg1.png');
    this.load.image('bg2', './game/fighterbun/images/bg/bg2.png');
    this.load.image('bg3', './game/fighterbun/images/bg/bg3.png');
    this.load.image('bg4', './game/fighterbun/images/bg/bg4.png');
    this.load.image('bg_prop1', './game/fighterbun/images/bg/bg_prop1.png');
    this.load.image('bg_prop2', './game/fighterbun/images/bg/bg_prop2.png');
    this.load.image('bg_super', './game/fighterbun/images/bg/bg_super.png');
    this.load.image('bg_super2', './game/fighterbun/images/bg/bg_super2.png');

    /* Hud */

    // round 1,2,3
    this.load.image('hud_r1', './game/fighterbun/images/hud/hud_r1.png');
    this.load.image('hud_r2', './game/fighterbun/images/hud/hud_r2.png');
    this.load.image('hud_r3', './game/fighterbun/images/hud/hud_r3.png');

    // prefight and postfight
    this.load.image('hud_ready', './game/fighterbun/images/hud/hud_ready.png');
    this.load.image('hud_fight', './game/fighterbun/images/hud/hud_fight.png');
    this.load.image('hud_ko', './game/fighterbun/images/hud/hud_ko.png');
    this.load.image('hud_youwon', './game/fighterbun/images/hud/hud_youwon.png');
    this.load.image('hud_youlost', './game/fighterbun/images/hud/hud_youlost.png');

    // healthbar and superbar
    this.load.image('hud_healthbar_pink', './game/fighterbun/images/hud/hud_healthbar_pink.png');
    this.load.image('hud_healthbar_black', './game/fighterbun/images/hud/hud_healthbar_dark.png');
    this.load.image('hud_healthbar_grey', './game/fighterbun/images/hud/hud_healthbar_grey.png');
    this.load.image('hud_healthbar_beige', './game/fighterbun/images/hud/hud_healthbar_beige.png');
    this.load.image('hud_superbar', './game/fighterbun/images/hud/hud_superbar_yellow.png');

    // badge
    this.load.image('hud_badge', './game/fighterbun/images/hud/hud_badge.png');
    //hud background
    this.load.image('hud_bg', './game/fighterbun/images/hud/hud_bg.png');

    /* VS */
    this.load.image('vs_bg', './game/fighterbun/images/vs/vs_bg.png');
    this.load.image('vs_port1', './game/fighterbun/images/vs/vs_port1.png');
    this.load.image('vs_port2', './game/fighterbun/images/vs/vs_port2.png');
    this.load.image('vs_vs', './game/fighterbun/images/vs/vs_vs.png');





    /* Font */
    this.load.bitmapFont('HalfBoldPixel-7', './game/generic/fonts/HalfBoldPixel-7.png', './game/generic/fonts/HalfBoldPixel-7.fnt');

    /* BGM */
    this.load.audio('bgm', './game/fighterbun/sound_mp3/bgm/POL-combat-plan-long.mp3');

    /* SFX */
    this.load.audio('block', './game/fighterbun/sound_mp3/battle/block.mp3');
    this.load.audio('jump', './game/fighterbun/sound_mp3/battle/jump01.mp3');

    /* UI */

    this.load.audio('ui_r1', './game/fighterbun/sound_mp3/ui/r01.mp3');
    this.load.audio('ui_r2', './game/fighterbun/sound_mp3/ui/r02.mp3');
    this.load.audio('ui_r3', './game/fighterbun/sound_mp3/ui/r03.mp3');

    this.load.audio('ui_ready', './game/fighterbun/sound_mp3/ui/ready01.mp3');
    this.load.audio('ui_fight', './game/fighterbun/sound_mp3/ui/fight01.mp3');
    this.load.audio('ui_ko', './game/fighterbun/sound_mp3/ui/ko.mp3');
    this.load.audio('ui_youlose', './game/fighterbun/sound_mp3/ui/youlose02.mp3');
    this.load.audio('ui_youwin', './game/fighterbun/sound_mp3/ui/youwin.mp3');
    this.load.audio('ui_gameover', './game/fighterbun/sound_mp3/ui/gameover02.mp3');

    /* attack sound*/

    this.load.audio('beam', './game/fighterbun/sound_mp3/battle/beam.mp3');
    this.load.audio('woosh_1', './game/fighterbun/sound_mp3/battle/woosh01.mp3');
    this.load.audio('woosh_2', './game/fighterbun/sound_mp3/battle/woosh02.mp3');

    this.load.audio('player_atk_1', './game/fighterbun/sound_mp3/player_voice/p_atk01.mp3');
    this.load.audio('player_atk_2', './game/fighterbun/sound_mp3/player_voice/p_atk02.mp3');
    this.load.audio('player_atk_3', './game/fighterbun/sound_mp3/player_voice/p_atk03.mp3');
    this.load.audio('player_atk_4', './game/fighterbun/sound_mp3/player_voice/p_atk04.mp3');
    this.load.audio('player_atk_5', './game/fighterbun/sound_mp3/player_voice/p_atk05.mp3');

    this.load.audio('enemy_atk_1', './game/fighterbun/sound_mp3/enemy_voice/e_atk01.mp3');
    this.load.audio('enemy_atk_2', './game/fighterbun/sound_mp3/enemy_voice/e_atk02.mp3');
    this.load.audio('enemy_atk_3', './game/fighterbun/sound_mp3/enemy_voice/e_atk03.mp3');
    this.load.audio('enemy_atk_4', './game/fighterbun/sound_mp3/enemy_voice/e_atk04.mp3');
    this.load.audio('enemy_atk_5', './game/fighterbun/sound_mp3/enemy_voice/e_atk05.mp3');

    /* hurt sound*/
    this.load.audio('smack_1', './game/fighterbun/sound_mp3/battle/smack01.mp3');
    this.load.audio('smack_2', './game/fighterbun/sound_mp3/battle/smack02.mp3');

    this.load.audio('player_ouch_3', './game/fighterbun/sound_mp3/player_voice/p_ouch03.mp3');
    this.load.audio('player_ouch_4', './game/fighterbun/sound_mp3/player_voice/p_ouch04.mp3');
    this.load.audio('player_scream', './game/fighterbun/sound_mp3/player_voice/p_scream.mp3');

    this.load.audio('enemy_ouch_3', './game/fighterbun/sound_mp3/enemy_voice/e_ouch03.mp3');
    this.load.audio('enemy_ouch_4', './game/fighterbun/sound_mp3/enemy_voice/e_ouch04.mp3');
    this.load.audio('enemy_scream', './game/fighterbun/sound_mp3/enemy_voice/e_scream.mp3');



    this.load.on('progress', (value) => {
      //
      let preloader_mesh = this.make.mesh({
        key: 'preloader_mask',
        x: 0,
        y: (value * 144) - 144,
        //-144 ~ 0,
        vertices: [
          quad.topLeftX, quad.topLeftY,
          quad.bottomLeftX, quad.bottomLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topLeftX, quad.topLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topRightX, quad.topRightY
        ],
        uv: [0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0],
        add: false
      });

      preloader_graphic.mask = new Phaser.Display.Masks.GeometryMask(this, preloader_mesh);
      // this.emitter.y.propertyValue = (value * 144)+258; //258 ~ 258+144
    });

    this.load.on('complete', () => {
      console.log('load complete');
      makeAnimations(this);
      attackData(this);
    });
  }

  create() {
    setTimeout(() => {
      this.preloaded();
    }, this.PRELOAD_TIMER);
    // console.log(this.cache);
  }

  preloaded() {
    this.emitter.killAll();
    this.scene.start(CST.SCENES.THROWBACK_TITLE, 'Throwback Title Scene');
  }
}
