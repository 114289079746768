import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class PreloadScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.LOAD
    });
  }

  init(data) {
    // console.log(data);
  }

  preload() {

    this.HEIGHT = 660;
    this.WIDTH = 660;
    this.CENTER_POINT_X = this.WIDTH / 2;
    this.CENTER_POINT_Y = this.HEIGHT / 2;
    this.PRELOAD_TIMER = 100;

    //Add black BG
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, this.WIDTH, this.HEIGHT);
    //Add stars
    this.particles = this.add.particles('particle');  
    this.emitter = this.particles.createEmitter({
      x: 330,
      y: 330,
      bounce: true,
      lifespan: 1000,
      alpha: 0.6,
      speed: {
        min: 300,
        max: 360
      },
      gravityY: 0,
      scale: {
        start: 1,
        end: .7
      },
      quantity: 1,
      blendMode: 'ADD',
      timeScale: 0.7
    });

    let quad = {
      topLeftX: 126,
      topLeftY: 258,
      topRightX: 660 - 126,
      topRightY: 258,
      bottomLeftX: 126,
      bottomLeftY: 660 - 258,
      bottomRightX: 660 - 126,
      bottomRightY: 660 - 258
    };

    let preloader_graphic = this.add.image(330, 330, 'preloader');

    let preloader_graphic_height = preloader_graphic.height;

    this.add.text(330 - 40, 660 - 190, 'PRESENTS...', {
      fontFamily: 'VT323',
      color: '#41b1e4'
    });

    this.load.bitmapFont('HalfBoldPixel-7', './game/generic/fonts/HalfBoldPixel-7.png', './game/generic/fonts/HalfBoldPixel-7.fnt');

    /* Loading Screens */
    this.load.spritesheet('throwback', './game/generic/throwback_title.png', {
      frameWidth: 660,
      frameHeight: 660
    });
    this.load.spritesheet('press_btn', './game/generic/press.png', {
      frameWidth: 660,
      frameHeight: 48
    });
    this.load.image('cookie_dough', './game/kart/images/screens/intro.png');
    this.load.spritesheet('instructions', './game/kart/images/screens/instructions.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.image('how_to_play', './game/kart/images/screens/how_to_play.png');
    this.load.spritesheet('how_to_play_2', './game/kart/images/screens/how_to_play_2.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.spritesheet('screen_bg', './game/kart/images/screens/screen_bg.png', {
      frameWidth: 330,
      frameHeight: 330
    });

    /* Intro */
    this.load.image('pink_intro_kart', './game/kart/images/intro/pink_kart.png');
    this.load.image('choc_intro_kart', './game/kart/images/intro/choc_kart.png');
    this.load.image('pink_intro_kart_move', './game/kart/images/intro/pink_kart_move.png');
    this.load.image('choc_intro_kart_move', './game/kart/images/intro/choc_kart_move.png');

    /* Karts */
    this.load.image('kart_pink', './game/kart/images/kart/kart_pink.png');
    this.load.image('kart_blue', './game/kart/images/kart/kart_blue.png');
    this.load.image('kart_brown_mushroom', './game/kart/images/kart/kart_brown_mushroom_1.png');
    this.load.image('kart_brown_mushroom_2', './game/kart/images/kart/kart_brown_mushroom_2.png');
    this.load.image('kart_brown', './game/kart/images/kart/kart_brown.png');
    this.load.image('kart_glazed', './game/kart/images/kart/kart_glazed.png');
    this.load.image('kart_mushroom', './game/kart/images/kart/kart_mushroom_1.png');
    this.load.image('kart_mushroom_2', './game/kart/images/kart/kart_mushroom_2.png');
    this.load.image('kart_purple', './game/kart/images/kart/kart_purple.png');
    this.load.image('kart_smoke_1', './game/kart/images/kart/kart_smoke_1.png');
    this.load.image('kart_smoke_2', './game/kart/images/kart/kart_smoke_2.png');
    this.load.image('kart_brown_smoke_1', './game/kart/images/kart/kart_brown_smoke_1.png');
    this.load.image('kart_brown_smoke_2', './game/kart/images/kart/kart_brown_smoke_2.png');
    this.load.image('kart_yellow', './game/kart/images/kart/kart_yellow.png');
    this.load.image('wheel', './game/kart/images/kart/wheels.png');
    this.load.image('kart_pink_no_wheels1', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00000.png');
    this.load.image('kart_pink_no_wheels2', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00001.png');
    this.load.image('kart_pink_no_wheels3', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00003.png');
    this.load.image('kart_pink_no_wheels4', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00004.png');
    this.load.image('kart_pink_no_wheels5', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00005.png');
    this.load.image('kart_pink_no_wheels6', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00006.png');
    this.load.image('kart_pink_no_wheels7', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00007.png');
    this.load.image('kart_pink_no_wheels8', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00008.png');
    this.load.image('kart_pink_no_wheels9', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00009.png');
    this.load.image('kart_pink_no_wheels10', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00010.png');
    this.load.image('kart_pink_no_wheels11', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00011.png');
    this.load.image('kart_pink_no_wheels12', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00012.png');
    this.load.image('kart_pink_no_wheels13', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00013.png');
    this.load.image('kart_pink_no_wheels14', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00014.png');
    this.load.image('kart_pink_no_wheels15', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00015.png');
    this.load.image('kart_pink_no_wheels16', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00016.png');
    this.load.image('kart_pink_no_wheels17', './game/kart/images/kart/kart_pink_no_wheels/kart_pink_no_wheels_00017.png');

    /* Hud */
    this.load.image('1', './game/kart/images/hud/1.png');
    this.load.image('2', './game/kart/images/hud/2.png');
    this.load.image('3', './game/kart/images/hud/3.png');
    this.load.image('go', './game/kart/images/hud/go.png');
    this.load.image('wrong', './game/kart/images/hud/wrong_way.png');
    this.load.image('time_out', './game/kart/images/hud/time_out.png');
    this.load.image('finished', './game/kart/images/hud/finished.png');
    this.load.image('left_flag', './game/kart/images/hud/left_flag.png');
    this.load.image('right_flag', './game/kart/images/hud/right_flag.png');



    /* Track Add Ons */
    this.load.image('box', './game/kart/images/track/box.png');
    this.load.image('star', './game/kart/images/power/star.png');
    this.load.image('chevron', './game/kart/images/track/chevron.png');
    this.load.image('slick', './game/kart/images/power/slick.png');
    this.load.image('jam', './game/kart/images/track/jam.png');
    this.load.image('empty', './game/kart/images/power/empty.png')
    this.load.image('shell', './game/kart/images/power/shell.png');
    this.load.image('shell_fire', './game/kart/images/power/shell_fire.png');
    this.load.image('coin', './game/kart/images/track/coin.png');
    this.load.image('coin_hud', './game/kart/images/track/coin_hud.png');
    this.load.image('grid', './game/kart/images/track/grid.png');
    this.load.image('grid_p1', './game/kart/images/track/grid_p1.png');

    this.load.image('line', './game/kart/images/river.png');
    this.load.image('tyre', './game/kart/images/track/doughnut.png');
    this.load.image('tyre_2', './game/kart/images/track/doughnut_2.png');
    this.load.image('grandstand', './game/kart/images/track/grandstand.png');

    /* Celebrations */
    this.load.image('spark', './game/brownie/images/character/spark.png');
    this.load.image('smoke', './game/kart/images/kart/smoke.png');

    /* Track */
    this.load.image('track', './game/kart/images/track/road-set.png');
    this.load.image('grass', './game/kart/images/track/cookie-set.png');
    this.load.tilemapTiledJSON('map', './game/kart/json/KrispyKart.json');

    /* Music */
    this.load.audio('background_sound', ['./game/kart/audio/RetroGameLoop.mp3']);
    this.load.audio('one', ['./game/kart/audio/1_y.mp3']);
    this.load.audio('two', ['./game/kart/audio/2_y.mp3']);
    this.load.audio('three', ['./game/kart/audio/3_y.mp3']);
    this.load.audio('go', ['./game/kart/audio/go_y.mp3']);
    this.load.audio('ching', ['./game/kart/audio/item1_y.mp3']);
    this.load.audio('item', ['./game/kart/audio/item2_y.mp3']);
    this.load.audio('vrm_j', ['./game/kart/audio/vrm_j.mp3']);
    this.load.audio('vrm_y', ['./game/kart/audio/vrm_y.mp3']);

    this.load.on('progress', (value) => {
      //
      let preloader_mesh = this.make.mesh({
        key: 'preloader_mask',
        x: 0,
        y: (value * 144) - 144,
        //-144 ~ 0,
        vertices: [
          quad.topLeftX, quad.topLeftY,
          quad.bottomLeftX, quad.bottomLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topLeftX, quad.topLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topRightX, quad.topRightY
        ],
        uv: [0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0],
        add: false
      });

      preloader_graphic.mask = new Phaser.Display.Masks.GeometryMask(this, preloader_mesh);
      // this.emitter.y.propertyValue = (value * 144)+258; //258 ~ 258+144
    });
  }

  create() {
    setTimeout(() => {
      this.preloaded();
    }, this.PRELOAD_TIMER);
  }

  preloaded() {
    this.emitter.killAll();
    // this.scene.stop(CST.SCENES.INIT);
    this.scene.start(CST.SCENES.THROWBACK_TITLE, 'Throwback Title Scene');
  }
}
