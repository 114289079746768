import { observable, action } from 'mobx';
import axios from 'axios';
import Pixel from './Pixel';

class DataStore {

    //Player
    @observable gamer_tag = null;
    @observable gamer_name = null;
    @observable signed = false;
    @observable social = null;
    @observable easter = false;
    
    //Views
    @observable pop_up_visible = false;

    //Game
    @observable game_id = null;
    @observable game_name = null;
    @observable locale = null;
    @observable score = 0;
    @observable mute = false;

    @observable pixel = null;
    
    /* Init Pixel */

    @action initPixel() {
        this.pixel = new Pixel();
        this.pixel.init(this.game_id, this.game_name, this.locale);
    }

    @action startGamePixel() {
        this.pixel.startGame(this.game_id, this.game_name, this.locale);
    }

    @action finishGamePixel() {
        this.pixel.finishGame(this.game_id, this.game_name, this.locale, this.score);
    }

    @action loaderboardPixel() {
        this.pixel.leaderboard(this.game_id, this.game_name, this.locale);
    }

    @action signupPixel() {
        this.pixel.signup(this.game_id, this.game_name, this.locale);
    }

    //Scores
    @observable scores = [];

    @action setEaster() {
        this.easter = true;
    }

    /* Store Gamer Name */

    @action setGamerName(name) {
        localStorage.setItem('throwback_krispy_gamer_name_v1uk_release', name);
    }

    @action getGamerName() {
        let name = localStorage.getItem('throwback_krispy_gamer_name_v1uk_release');
        return name;
    }

     /* Store Gamer Locale */

     @action setGamerLocale(locale) {
        this.locale = locale;
        localStorage.setItem('throwback_krispy_gamer_locale_v1uk_release', locale);
    }

    @action getGamerLocale() {
        let locale = localStorage.getItem('throwback_krispy_gamer_locale_v1uk_release');
        return locale;
    }

    /* Store Gamer Tag */

    @action setGamerTag(tag) {
        localStorage.setItem('throwback_krispy_gamer_tag_v1uk_release', tag);
    }

    @action getGamerTag() {
        let tag = localStorage.getItem('throwback_krispy_gamer_tag_v1uk_release');
        return tag;
    }

    /* Store Gamer Tag */

    @action setSigned(signed) {
        localStorage.setItem('throwback_krispy_signed_v1uk_release', signed);
        this.signupPixel();
    }

    @action getSigned() {
        let signed = localStorage.getItem('throwback_krispy_signed_v1uk_release');
        if(signed === "true") {
            this.signed = signed;
        } else {
            this.signed = false;
        }
        return this.signed;
    }

    //Set Slide up.

    @action setVisibility(visible) {
        this.pop_up_visible = visible;
    }

    /* Get Score */

    @action setScore(score) {
        this.score = score;
    }

    @action getScore() {
        return this.score; 
    }

    @action sendScore(callback) {

        axios({
            method: 'post',
            url: '/api/submit_score',
            data: {
                /* set after registration */
                gamer_tag: this.getGamerTag(),
                gamer_name: this.getGamerName(),
                /* set after game */
                gamer_score: this.score,
                /* set on init */
                game_id: this.game_id,
                gamer_locale: this.locale
            }
        })
        .then((response) => {
            let top10 = null;
            let res = response.data;
            
            if(res.length >= 10) {
                if(response.data[10] !== null && response.data[10] !== undefined && response.data[10].rank <= 10) {
                    top10 = response.data[10].rank;
                    res.forEach(function(element, i) { 
                        if(i + 1 === top10) {
                            element.user = true; 
                        } else {
                            element.user = false; 
                        }
                    });
                    res.pop();
                } else {
                    res.forEach(function(element) { element.user = false; });
                }
            } else {
                let length = res.length;
                top10 = response.data[length -1].rank;
                res.forEach(function(element, i) { 
                    if(i + 1 === top10) {
                        element.user = true; 
                    } else {
                        element.user = false; 
                    }
                });
                res.pop();
            }
           
            this.scores = res;
            callback(res);
        });
    }

    @action registerUser(email, name, tag, callback) {
        axios({
            method: 'post',
            url: '/api/register_user',
            data: {
                gamer_tag: tag,
                gamer_name: name,
                gamer_email: email
            }
        })
        .then((response) => {
            callback(response)
        });
    }

    @action getLeaderboardScore() {
        return this.scores; 
    }
}

const datastore = new DataStore();
export default datastore;
