import React, { Component } from 'react';
import datastore from '../../Store/DataStore';
import Close from '../Close';

export default class Share extends Component {
  render() {
    return (
      <div className="window share">
        <div className={`application ${this.props.window_state ? '' : 'application_close'}`}>
            <div className="header">
              <div className="lines"><hr/><hr/><hr/></div>
              <div className="header_text">
                <p>{this.props.header}</p>
              </div>
              { this.props.close ? <Close close={this.props.close_point}/> : null }
            </div>
            <div className="application_body">  

            <div className="inner_application">
              <h3 className="inner_application_headline">Brag about your score</h3>
              <p className="inner_application_body">
              Boo ya! <span>Screenshot the leaderboard</span> on the next screen to share your score.<br/><br/> Don’t forget to tag {datastore.getGamerLocale() === "ie" ? <span>@krispykremeirl</span> : <span>@krispykremeuk</span>} so we can see your greatest hits!</p>
              <a className="inner_application_button" onClick={this.props.next}>next</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


 