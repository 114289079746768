import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class InitScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.INIT
    });
  }

  init(passedData) {}

  preload() {
    // console.log('inital load of preloader graphics');
    this.load.image('preloader', './game/generic/preloader_logo.png');
    this.load.image('particle', './game/generic/particle.png');
  }

  create() {
    this.scene.start(CST.SCENES.LOAD);
  }
}
