import React, { Component } from 'react'
import datastore from '../../Store/DataStore';
import Close from '../Close';
import Squishy from '../SquishyButton';
import SquishyScore from '../SquishyScore';


export default class GameOver extends Component {
  render() {
    return (
      <div className="window">
        <div className={`application ${this.props.window_state ? '' : 'application_close'}`}>

            <div className="header">
              <div className="lines"><hr/><hr/><hr/></div>
              <div className="header_text">
                <p>{this.props.header}</p>
              </div>
              { this.props.close ? <Close/> : null }
            </div>

            <div className="application_body">  
              <div className="inner_application">
                <h1 className="score_readout">BE IN IT TO <span>WIN</span> IT!</h1>
                <p className="inner_application_body">Add your score to the leaderboard for the glory AND a chance to <span>WIN BIG PRIZES</span>*.</p>
                <div className="prizes">
                  <img src="/generic/prizes.gif" />
                </div>
                <div className="buttons">
                  <div className="wrapper" onClick={this.props.signup}>
                    <SquishyScore score={datastore.score} game_over={'game_over'}/>
                  </div>
                  <div className="wrapper" onClick={this.props.replay}>
                    <Squishy pink={'pink'} game_over={'game_over'} />
                  </div>
                </div>
                {datastore.getGamerLocale() === "ie" ?
                    <p className="terms">* <a target="_blank" href="https://www.krispykreme.ie/competitions">Click here</a> for full terms and conditions.</p>
                    : 
                    <p className="terms">* <a target="_blank" href="https://www.krispykreme.co.uk/competitions">Click here</a> for full terms and conditions.</p> 
                }
              </div>
            </div>
        </div>          
      </div>
    )
  }
}

