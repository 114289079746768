import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class ThrowbackTitleScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.THROWBACK_TITLE
    });
  }

  preload() {

  }

  create() {

    this.mainImage = this.add.sprite(330, 330, 'throwback');


    this.scene.launch(CST.SCENES.CONTROL);
    this.control = this.scene.get(CST.SCENES.CONTROL);

    this.time.delayedCall(1.5 * 1000, function() {
      this.understood();
    }, [], this);

  }

  update() {
    if (this.control.keys.punch.isDown || this.control.keys.kick.isDown) {
      this.control.keyPressed = true;
    } else {
      if (this.control.keyPressed) {
        this.control.keyPressed = false;
        this.understood();

      }
    }
  }

  understood() {
    this.scene.start(CST.SCENES.GAME_TITLE);

  }
  pressAnyKey() {
    this.input.keyboard.on('keydown', function(event) {
      this.understood();
    }, this);

  }
}
