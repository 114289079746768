import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class ThrowbackTitleScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.THROWBACK_TITLE
    });
  }

  init(data) {
    this.SCREEN_TIMER = 3000;

    document.addEventListener("mute", function(event) {
      this.game.sound.mute = true;
    }.bind(this));

    document.addEventListener("unmute", function(event) {
      this.game.sound.mute = false;
    }.bind(this));
  }

  preload() {

  }

  create() {

    this.mainImage = this.add.sprite(330, 330, 'throwback');


    this.bgm = [this.game.sound.add('background_sound', {
      loop: true,
      volume: 0.4
    })];
    this.bgm[0].play();

    setTimeout(() => {
      this.understood();
    }, this.SCREEN_TIMER);
  }

  understood() {
    this.scene.start(CST.SCENES.KRISPYKART_TITLE, 'Krispy Kart Title Scene');
  }
}
