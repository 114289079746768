import Phaser from 'phaser';
import {
  CST
} from '../CST';

export class PreloadScene extends Phaser.Scene {

  constructor() {
    super({
      key: CST.SCENES.LOAD
    });
  }

  init(data) {
    // console.log(data);
  }

  preload() {

    this.HEIGHT = 660;
    this.WIDTH = 660;
    this.CENTER_POINT_X = this.WIDTH / 2;
    this.CENTER_POINT_Y = this.HEIGHT / 2;
    this.PRELOAD_TIMER = 100;

    //Add black BG
    const background = this.add.graphics();
    background.fillStyle(0x000000, 1);
    background.fillRect(0, 0, this.WIDTH, this.HEIGHT);
    //Add stars
    this.particles = this.add.particles('particle');
    this.emitter = this.particles.createEmitter({
      x: 330,
      y: 330,
      bounce: true,
      lifespan: 1000,
      alpha: 0.6,
      speed: {
        min: 300,
        max: 360
      },
      gravityY: 0,
      scale: {
        start: 1,
        end: .7
      },
      quantity: 1,
      blendMode: 'ADD',
      timeScale: 0.7
    });

    let quad = {
      topLeftX: 126,
      topLeftY: 258,
      topRightX: 660 - 126,
      topRightY: 258,
      bottomLeftX: 126,
      bottomLeftY: 660 - 258,
      bottomRightX: 660 - 126,
      bottomRightY: 660 - 258
    };

    let preloader_graphic = this.add.image(330, 330, 'preloader');

    let preloader_graphic_height = preloader_graphic.height;

    this.add.text(330 - 40, 660 - 190, 'PRESENTS...', {
      fontFamily: 'VT323',
      color: '#41b1e4'
    });

    this.load.bitmapFont('HalfBoldPixel-7', './game/generic/fonts/HalfBoldPixel-7.png', './game/generic/fonts/HalfBoldPixel-7.fnt');

    /* Loading Screens */
    this.load.spritesheet('throwback', './game/generic/throwback_title.png', {
      frameWidth: 660,
      frameHeight: 660
    });
    this.load.spritesheet('press_btn', './game/generic/press.png', {
      frameWidth: 660,
      frameHeight: 48
    });

    this.load.image('brownie_batter_intro', './game/brownie/images/screens/intro.png');
    this.load.image('eyes_open', './game/brownie/images/screens/eyes_open.png');
    this.load.image('eyes_closed', './game/brownie/images/screens/eyes_closed.png');
    this.load.spritesheet('instructions', './game/brownie/images/screens/instructions.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.image('how_to_play', './game/brownie/images/screens/how_to_play.png');
    this.load.spritesheet('how_to_play_2', './game/brownie/images/screens/how_to_play_2.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.spritesheet('screen_bg', './game/brownie/images/screens/screen_bg.png', {
      frameWidth: 330,
      frameHeight: 330
    });
    this.load.image('eyes_closed', './game/brownie/images/screens/eyes_closed.png');

    /* Intro */
    this.load.image('intro1', './game/brownie/images/screens/intro/intro1.png');
    this.load.image('intro2', './game/brownie/images/screens/intro/intro2.png');
    this.load.image('intro3', './game/brownie/images/screens/intro/intro3.png');
    this.load.image('intro4', './game/brownie/images/screens/intro/intro4.png');
    this.load.image('intro5', './game/brownie/images/screens/intro/intro5.png');
    this.load.image('intro6', './game/brownie/images/screens/intro/intro6.png');
    this.load.image('intro7', './game/brownie/images/screens/intro/intro7.png');
    this.load.image('intro8', './game/brownie/images/screens/intro/intro8.png');
    this.load.image('intro9', './game/brownie/images/screens/intro/intro9.png');

    this.load.spritesheet('bash_bounce', './game/brownie/images/screens/intro/bash_bounce.png', {
      frameWidth: 16,
      frameHeight: 32
    });


    /* Brownie */

    this.load.spritesheet('box', './game/brownie/images/box/box.png', {
      frameWidth: 33,
      frameHeight: 13
    });
    // this.load.image('small_box', './game/brownie/images/box/small_box.png');
    // this.load.image('large_box', './game/brownie/images/box/large_box.png');
    // this.load.image('large_box_broken', './game/brownie/images/box/large_box_broken.png');

    this.load.image('static', './game/brownie/images/character/static.png');
    this.load.image('static_eyes', './game/brownie/images/character/static_eyes.png');
    this.load.image('static_arm_up', './game/brownie/images/character/static_arm_up.png');
    this.load.image('static_double_arm_up', './game/brownie/images/character/static_double_arm_up.png');

    this.load.image('super_nut', './game/brownie/images/character/super_cape.png');
    this.load.image('super_nut_1', './game/brownie/images/character/super_cape_flap_1.png');
    this.load.image('super_nut_2', './game/brownie/images/character/super_cape_flap_2.png');


    this.load.image('dead', './game/brownie/images/character/hit.png');
    this.load.image('super_dead', './game/brownie/images/character/super_hit.png');
    this.load.image('ghostie', './game/brownie/images/character/ghostie.png');

    this.load.image('spark', './game/brownie/images/character/spark.png');
    this.load.image('choc_spark', './game/brownie/images/character/choc_spark.png');
    this.load.image('background', './game/brownie/images/shop/background.png');

    /* Doughnuts */
    this.load.image('bagel', './game/brownie/images/doughnuts/bagel.png');
    this.load.image('choc_sprinkles', './game/brownie/images/doughnuts/choc_sprinkles.png');
    this.load.image('choc', './game/brownie/images/doughnuts/choc.png');
    this.load.image('coffee', './game/brownie/images/doughnuts/coffee.png');
    this.load.image('doughnut', './game/brownie/images/doughnuts/doughnut.png');
    this.load.image('heart', './game/brownie/images/doughnuts/heart.png');
    this.load.image('original', './game/brownie/images/doughnuts/original.png');
    this.load.image('shake', './game/brownie/images/doughnuts/shake.png');
    this.load.image('sprinkles', './game/brownie/images/doughnuts/sprinkles.png');
    this.load.image('sundae', './game/brownie/images/doughnuts/sundae.png');
    this.load.image('sundae_1', './game/brownie/images/doughnuts/sundae_1.png');
    this.load.image('sundae_2', './game/brownie/images/doughnuts/sundae_2.png');
    this.load.image('brick', './game/brownie/images/doughnuts/brick.png');
    this.load.spritesheet('explosion', './game/brownie/images/doughnuts/bb_explosion.png', {
      frameWidth: 12,
      frameHeight: 12
    });
    this.load.image('4x', './game/brownie/images/doughnuts/4x.png');
    this.load.image('line', './game/brownie/images/doughnuts/line.png');
    this.load.image('dot', './game/brownie/images/doughnuts/dot.png');


    /* Hud */

    this.load.image('live_left', './game/brownie/images/lives/live_left.png');
    this.load.image('live_expired', './game/brownie/images/lives/live_expired.png');
    this.load.image('bar_top', './game/brownie/images/hud/top.png');
    this.load.image('super', './game/brownie/images/hud/super.png');
    this.load.spritesheet('super_full', './game/brownie/images/hud/super_full.png', {
      frameWidth: 67,
      frameHeight: 6
    });
    this.load.image('super_bar', './game/brownie/images/hud/super_empty.png');
    this.load.image('level_up_background', './game/brownie/images/screens/level_background.png');
    this.load.image('level_x', './game/brownie/images/screens/level_x.png');

    this.load.spritesheet('numbers', './game/brownie/images/hud/numbers.png', {
      frameWidth: 40,
      frameHeight: 29
    });
    this.load.image('frame_brown', './game/brownie/images/screens/frame_brown.png');

    /* Add Ons */

    this.load.image('coin_front', './game/brownie/images/hud/coin_front.png');
    this.load.image('coin_mid', './game/brownie/images/hud/coin_mid.png');
    this.load.image('coin_side', './game/brownie/images/hud/coin_side.png');

    /* Music */

    this.load.audio('background_sound', ['./game/brownie/audio_mp3/POL-clockwork-tale-long.mp3']);

    this.load.audio('coin', ['./game/brownie/audio_mp3/coin.mp3']);
    // this.load.audio('die', ['./game/brownie/audio_mp3/die.mp3']);
    // this.load.audio('plop', ['./game/brownie/audio_mp3/plop.mp3']);
    this.load.audio('woosh', ['./game/brownie/audio_mp3/woosh02.mp3']);
    //
    // this.load.audio('ding', ['./game/brownie/audio_mp3/ding.mp3']);
    // this.load.audio('blip', ['./game/brownie/audio_mp3/blip.mp3']);
    // this.load.audio('beeep', ['./game/brownie/audio_mp3/beeep.mp3']);
    // this.load.audio('toosh', ['./game/brownie/audio_mp3/toosh.mp3']);
    // this.load.audio('bibip', ['./game/brownie/audio_mp3/bibip.mp3']);
    //


    this.load.audio('dding1', ['./game/brownie/audio_mp3/dding1.mp3']);
    this.load.audio('dding2', ['./game/brownie/audio_mp3/dding2.mp3']);
    this.load.audio('ddong', ['./game/brownie/audio_mp3/ddong.mp3']);
    this.load.audio('aww', ['./game/brownie/audio_mp3/aww.mp3']);
    this.load.audio('super', ['./game/brownie/audio_mp3/super1.mp3']);
    this.load.audio('yay', ['./game/brownie/audio_mp3/yay.mp3']);
    this.load.audio('nextlevel', ['./game/brownie/audio_mp3/nextlevel.mp3']);
    // this.load.audio('ohyeah', ['./game/brownie/audio_mp3/ohyeah.mp3']);
    this.load.audio('yum', ['./game/brownie/audio_mp3/yum.mp3']);

    this.load.audio('attack', ['./game/brownie/audio_mp3/attack2.mp3']);
    // this.load.audio('p_atk02', ['./game/brownie/audio_mp3/p_atk02.mp3']);
    // this.load.audio('p_atk03', ['./game/brownie/audio_mp3/p_atk03.mp3']);




    /* Animation */




    this.load.on('progress', (value) => {
      //
      let preloader_mesh = this.make.mesh({
        key: 'preloader_mask',
        x: 0,
        y: (value * 144) - 144,
        //-144 ~ 0,
        vertices: [
          quad.topLeftX, quad.topLeftY,
          quad.bottomLeftX, quad.bottomLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topLeftX, quad.topLeftY,
          quad.bottomRightX, quad.bottomRightY,
          quad.topRightX, quad.topRightY
        ],
        uv: [0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0],
        add: false
      });

      preloader_graphic.mask = new Phaser.Display.Masks.GeometryMask(this, preloader_mesh);
      // this.emitter.y.propertyValue = (value * 144)+258; //258 ~ 258+144
    });


    this.load.on('complete', () => {
      // console.log('load complete');
    });
  }

  create() {
    setTimeout(() => {
      this.preloaded();
    }, this.PRELOAD_TIMER);
  }

  preloaded() {
    this.emitter.killAll();
    this.scene.start(CST.SCENES.THROWBACK_TITLE, 'Throwback Title Scene');
  }
}
