import React, { Component } from 'react'
import SimpleReactValidator from 'simple-react-validator';
import swearjar from 'swearjar';
import datastore from '../../Store/DataStore';
import Close from '../Close';

export default class Signup extends Component {
  
  constructor(props) {
    super();

    swearjar.isSubstringProfane = function(text, start = 0, minPartialLength = 3) {
      if (typeof text !== 'string') {
        return false;
      }
      const WORD_SEP_REGEX = /\s+/;
      const words = text.split(WORD_SEP_REGEX);
      for (let wordIdx = 0; wordIdx < words.length; wordIdx += 1) {
        const word = words[wordIdx].trim().toLowerCase();
        for (let partialLength = minPartialLength; partialLength <= text.length; partialLength += 1) {
          const maxStart = text.length - partialLength;
          for (let curStart = start; curStart <= maxStart; curStart += 1) {
            const partialWord = word.slice(curStart, curStart + partialLength);
    
            if (this.profane(partialWord)) {
              return true;
            }
          }
        }
      }
    
      return false;
    }



    this.state = {
      nickname_placeholder: 'Your Nickname',
      nickname: "",
      name: "",
      email: "",
      accepted: "off",
      nickname_valid: true,
      name_valid: true,
      email_valid: true
    }

    this.validator = new SimpleReactValidator({
      messages: {
          required: "Radical :attribute required",
          alpha_num_dash_space: "Only valid characters",
          max: "too long max is 15",
          accepted: "Please agree to the T&C's",
          email: "Enter a valid email",
          taken : "Oh snap! Nickname taken"
      },
      validators: {
        naugties: {  // name the rule
          message: 'Nickname is totes inappropes',
          rule: (val, params, validator) => {
            let isValid = swearjar.isSubstringProfane(val);
            return isValid = !isValid
          },
          messageReplace: (message, params) => message.replace(':values', this.state.name), 
          required: true
        },
        taken: {  // name the rule
          message: 'Oh snap! Nickname taken',
          rule: (val, params, validator) => {
            return this.state.nickname_valid
          },
          messageReplace: (message, params) => message.replace(':values', this.state.name), 
          required: true
        },
        email_required: {
          message: 'Need your email dude',
          rule: (val, params, validator) => {
            let isValid = val.length === 0 ? false : true;
            return isValid
          },
          required: true
        }
      }
    });
    
    this.submitForm = this.submitForm.bind(this);
  }
  
  resizeFocus() {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 1000 );
  }

  submitForm(e) {
    e.preventDefault();

    if (this.validator.allValid()) {    
      datastore.registerUser(this.state.email, this.state.name, this.state.nickname, (data) => {
        if(data.data === "gamer_tag_logged") {
          datastore.setGamerName(this.state.name);
          datastore.setGamerTag(this.state.nickname);
          datastore.sendScore(() => {
            datastore.setSigned(true);
          });
          this.props.signedup();
        } else {
          this.setState({
            nickname_valid: false
          });
          this.validator.showMessages();
          this.forceUpdate();
        }
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }

    this.setState({
      email_valid: this.validator.fieldValid('email'),
      name_valid: this.validator.fieldValid('name'),
      nickname_valid: this.validator.fieldValid('nickname')
    })
  }

  setStateFromInput (event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(target.name === 'nickname') {
      this.setState({
        nickname_valid: true
      });
    }

    this.setState({
      [name]: value
    });
  }

  render() {
    return (
      <div className="window signup">
        <div className={`application ${this.props.window_state ? '' : 'application_close'}`}>
            <div className="header">
              <div className="lines"><hr/><hr/><hr/></div>
              <div className="header_text">
                <p>{this.props.header}</p>
              </div>
              { this.props.close ? <Close close={this.props.close_point} /> : null }
            </div>
            <div className="application_body">
                <form id="Krispy_Kreme_Throwback" className="krispy_kreme_throwback_form">
                    
                    <legend>Rad score! Add it to the leaderboard.</legend>
                    
                    <div className={`form-group ${this.state.nickname_valid ? '' : 'invalid'}`}>
                      <label>Your Nickname</label>
                      <input onBlur={this.resizeFocus} className="form-control signup_field" name="nickname" type="text" value={this.state.nickname} maxLength="15" onChange={this.setStateFromInput.bind(this)} placeholder={"Your Nickname"} />
                      {this.validator.message('nickname', this.state.nickname, 'required|alpha_num_dash_space|max:15|naugties|taken')}
                    </div>

                    <div className={`form-group ${this.state.name_valid ? '' : 'invalid'}`}>
                      <label>Your Name</label>
                      <input onBlur={this.resizeFocus} className="form-control signup_field" name="name" type="text" value={this.state.name} maxLength="40" onChange={this.setStateFromInput.bind(this)} placeholder="Your Name" />
                      {this.validator.message('name', this.state.name, 'required|alpha_num_dash_space|max:40')}
                    </div>

                    <div className={`form-group ${this.state.email_valid ? '' : 'invalid'}`}>
                      <label>Your Email</label>
                      <input onBlur={this.resizeFocus} className="form-control signup_field" name="email" type="text" value={this.state.email} onChange={this.setStateFromInput.bind(this)} placeholder="Your Email" />
                      {this.validator.message('email', this.state.email, 'email_required|email')}
                    </div>

                    <div className="form-group check">
                      <label>Terms and Conditions</label>
                      <input className="form-control" type="checkbox" name="accepted" className="form-check-input" selected={this.state.accepted} onChange={this.setStateFromInput.bind(this)} />
                      <p className="checkbox_blurb">Yep, I agree to the {datastore.getGamerLocale() === "ie" ? <a target="_blank" href="https://www.krispykreme.ie/arcade">T&amp;C's</a> : <a target="_blank" href="https://www.krispykreme.co.uk/arcade">T&amp;C's</a> }.</p>
                      {this.validator.message('checkbox', this.state.accepted, 'accepted')}
                    </div>
                    
                    <div className="form-group">
                      <button className="squishy pink" onClick={this.submitForm}>Post Your Score</button>
                    </div>
                    {datastore.getGamerLocale() === "ie" ?
                      <p className="terms">When you submit your deets we handle them with care in accordance with our <a target="_blank" href="https://www.krispykreme.ie/privacy-policy">privacy policy</a> and our <a target="_blank" href="https://www.krispykreme.co.uk/cookie-policy">cookies policy</a>.</p>
                      : 
                      <p className="terms">When you submit your deets we handle them with care in accordance with our <a target="_blank" href="https://www.krispykreme.co.uk/privacy-policy">privacy policy</a> and our <a target="_blank" href="https://www.krispykreme.ie/cookie-policy">cookies policy</a>.</p> 
                    }   
                </form>

            </div>
          </div>
        </div>
    )
  }
}